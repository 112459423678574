// import { URLSearchParams } from "url";

// import * as complex from "./complex";

import { Pane } from "tweakpane";

const pane = new Pane();

const canvas = document.getElementById("canvas") as HTMLCanvasElement;
const context = canvas.getContext("webgl2", { antialias: true });

const overlay = document.getElementById("overlay") as HTMLCanvasElement;
const overlayContext = canvas.getContext("2d");

const mouseElement = document.getElementById("mouse");
const mouseoverElement = document.getElementById("mouseover");

let size = Math.max(window.innerWidth, window.innerHeight);

canvas.width = window.innerWidth;
canvas.height = window.innerHeight;

const gpu = new (window as any).GPUX({ canvas, context });

// let width = window.innerWidth;
// let height = window.innerHeight;
// let ratio = (width > height) ? width / height : height / width;

let scale = 5;

// const urlParams = new URLSearchParams(window.location.search);
// const period = urlParams.has("p") ? parseInt(urlParams.get("p")) : 10;

type color = [number, number, number];

// function mandlebrot(c: [number, number], T: number): color {

//     let z = c.slice() as [number, number];

//     for (let i = 1; i < 500; i++) {
//         z = complex.add(complex.mul(z, z), c);
//         if (complex.dist2(z) > 4) {
//             return [i, i, i];
//         }
//     }

//     return [0, 0, 0];
// }

type complex = [number, number];

export function add(a: complex, b: complex): complex {
    return [a[0] + b[0], a[1] + b[1]];
}

export function mul(a: complex, b: complex): complex {
    return [a[0] * b[0] - a[1] * b[1], a[0] * b[1] + a[1] * b[0]];
}

export function scalardiv(a: complex, s: number): complex {
    return [a[0] / s, a[1] / s];
}

export function scalarmul(a: complex, s: number): complex {
    return [a[0] * s, a[1] * s];
}

export function cdiv(a: complex, b: complex): complex {
    const num = mul(a, [b[0], -b[1]]);
    const den = dist2(b);
    return scalardiv(num, den);
}

export function csin(a: complex): complex {
    // sin = x - x^3 / 3 + x^5 / 5 ...
    const squared = mul(a, a);
    const cubed = mul(a, squared);
    const fifth = mul(cubed, squared);
    const seventh = mul(fifth, squared);
    return add(
        a,
        add(
            scalardiv(cubed, -6),
            add(scalardiv(fifth, 120), scalardiv(seventh, -5040))
        )
    );
}

export function ccos(a: complex): complex {
    // cosine = 1 - x^2 / 2 + x^4 / 4 ...
    const squared = mul(a, a);
    return add(
        add(scalardiv(a, -2), scalardiv(mul(squared, squared), 24)),
        [1, 0]
    );
}

export function cexp(a: complex): complex {
    // exp = 1 + x/1! + x^2/2! + x^3/3! ...
    const squared = mul(a, a);
    const cubed = mul(squared, a);
    const fourth = mul(cubed, a);

    return add(
        [1, 0],
        add(
            scalardiv(squared, 2),
            add(scalardiv(cubed, 6), scalardiv(fourth, 24))
        )
    );
}

export function angle(a: complex): number {
    const [x, y] = a;
    if (x > 0) {
        return Math.atan(y / x);
    } else if (x < 0 && y >= 0) {
        return Math.atan(y / x) + Math.PI;
    } else if (x < 0 && y < 0) {
        return Math.atan(y / x) - Math.PI;
    } else if (x == 0 && y > 0) {
        return Math.PI / 2;
    } else if (x == 0 && y < 0) {
        return -Math.PI / 2;
    }

    return 0;
}

// export function clog(a: complex): complex {
// return [Math.log(Math.sqrt(dist2(a))), angle(a)];
// }

export function spow(x: complex, s: number): complex {
    const rx = dist2(x);
    const thx = angle(x);

    const rz = Math.pow(rx, s / 2);
    const thz = thx * s;

    return [rz * Math.cos(thz), rz * Math.sin(thz)];
}

export function cpow(x: complex, y: complex): complex {
    const lnrx = 0.5 * Math.log(dist2(x));
    const thx = angle(x);

    const ay = y[0];
    const by = y[1];

    const rz = Math.exp(lnrx * ay - thx * by);
    const thz = lnrx * by + thx * ay;

    return [rz * Math.cos(thz), rz * Math.sin(thz)];
}

export function expi(T: number): complex {
    return [Math.cos(T), Math.sin(T)];
}

// export function dist(a: complex): number {
//     return Math.sqrt(dist2(a));
// }

export function dist2(a: complex): number {
    return a[0] * a[0] + a[1] * a[1];
}

// z = cpow(z, add(expi(T), c)) // Very cool
// z = cpow(expi(T), add(z, c)); // flippy mountains
// z = cpow(cpow(expi(T), z), cpow(c, expi(T))); // wild flames
// z = add(cpow(z, z), c); // upvote
// z = mul(z, cpow(z, add(expi(T), c)))

function f(c: [number, number], T: number): color {
    let z = [c[0], c[1]] as any;

    for (let i = 1; 100; i++) {
        // z = add(cpow(expi(T), z), add(expi(T), cpow(c, expi(T)))); // mean tree
        // z = add(cpow(z, cpow(expi(T), c)), c); // dark forest
        z = add(mul(z, z), c);

        if (dist2(z) > 4) {
            return [i, i, i];
        }
    }

    return [0, 0, 0];
}

gpu.addFunction(add);
gpu.addFunction(mul);
// gpu.addFunction(dist);
gpu.addFunction(dist2);
gpu.addFunction(scalarmul);
gpu.addFunction(scalardiv);
gpu.addFunction(cdiv);
gpu.addFunction(csin);
gpu.addFunction(cexp);
gpu.addFunction(ccos);
gpu.addFunction(cpow);
// gpu.addFunction(clog);
gpu.addFunction(angle);
gpu.addFunction(spow);
gpu.addFunction(expi);
// gpu.addNativeFunction("f", fn.toString(), { returnType: "Array(2)"})
// gpu.addFunction(mandlebrot);
gpu.addFunction(f);

function generateColors(functionString: string) {
    const l = functionString
        .toString()
        .split("")
        .reduce((acc, c) => acc + c.charCodeAt(0), 0);
    const mods = [
        ((l * 100271) % 50) + 50,
        ((l * 999931) % 50) + 50,
        ((l * 999671) % 50) + 50,
    ];
    return [
        (l * 100169) % mods[0],
        (l * 131071) % mods[1],
        (l * 524287) % mods[2],
    ];
}

function kernelFunction(
    T: number,
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    colors: number[]
) {
    const ratio = width > height ? width / height : height / width;

    const a = this.thread.x;
    const b = this.thread.y;
    const px = ((a - width / 2 - x / scale) / width) * scale;
    // const px = scale * (a - width / 2) - x / width;
    const py = ((b - height / 2 + y / scale) / height) * scale;

    this.color(a / width, b / height, 0);

    const color = f([px, py], T);

    this.color(
        (color[0] % colors[0]) / colors[0],
        (color[1] % colors[1]) / colors[1],
        (color[2] % colors[2]) / colors[2]
    );
}

const render = gpu.createKernel(kernelFunction);

render.setOutput([size, size]);
render.setGraphical(true);
render.setDynamicOutput(true);

window.addEventListener("resize", () => {
    size = Math.max(window.innerWidth, window.innerHeight);
    // ratio = (width > height) ? width / height : height / width;
    render.setOutput([window.innerWidth, window.innerHeight]);
});

const mouse = {
    x: 0,
    y: 0,
    lastx: 0,
    lasty: 0,
    down: false,
};

let touchStart = [0, 0];
let startDistance = 0;

canvas.addEventListener("touchstart", (e) => {
    e.preventDefault();
    if (e.touches.length === 2) {
        let dx = e.touches[0].pageX - e.touches[1].pageX;
        let dy = e.touches[0].pageY - e.touches[1].pageY;
        startDistance = Math.sqrt(dx * dx + dy * dy);

        const middleX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
        const middleY = (e.touches[0].pageY + e.touches[1].pageY) / 2;

        mouse.down = true;
        mouse.x = middleX;
        mouse.y = middleY;
        mouse.lastx = mouse.x;
        mouse.lasty = mouse.y;

        return;
    }

    mouse.down = true;
    mouse.x = e.touches[0].clientX;
    mouse.y = e.touches[0].clientY;
    mouse.lastx = mouse.x;
    mouse.lasty = mouse.y;
});

canvas.addEventListener("touchend", (e) => {
    mouse.down = false;
});

canvas.addEventListener("touchmove", (e) => {
    e.preventDefault();
    if (e.touches.length === 2) {
        let dx = e.touches[0].pageX - e.touches[1].pageX;
        let dy = e.touches[0].pageY - e.touches[1].pageY;
        const middleX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
        const middleY = (e.touches[0].pageY + e.touches[1].pageY) / 2;
        let currentDistance = Math.sqrt(dx * dx + dy * dy);
        let diff = currentDistance - startDistance;
        startDistance = currentDistance;

        const scaleFactor = sigmoid(-diff / 100) * 2;

        let x = (middleX - size / 2) * params.scale;
        let y = (middleY - size / 2) * params.scale;

        params.scale *= scaleFactor;
        params.panx -= x - (middleX - size / 2) * params.scale;
        params.pany -= y - (middleY - size / 2) * params.scale;

        mouse.x = middleX;
        mouse.y = middleY;

        return;
    }
    var cRect = canvas.getBoundingClientRect();
    mouse.x = e.touches[0].pageX - cRect.left;
    mouse.y = e.touches[0].pageY - cRect.top;
});

canvas.addEventListener("mousemove", (e) => {
    var cRect = canvas.getBoundingClientRect();
    mouse.x = e.clientX - cRect.left;
    mouse.y = e.clientY - cRect.top;
});

canvas.addEventListener("mousedown", () => {
    mouse.down = true;
});

canvas.addEventListener("mouseup", () => {
    mouse.down = false;
});

let paused = false;

document.addEventListener("keypress", (e) => {
    if (e.key === " ") {
        paused = !paused;
    }
});

let panx = (-Math.abs(window.innerWidth - size) / 2) * scale,
    pany = (-Math.abs(window.innerHeight - size) / 2) * scale;
let lastFrame = 0;
const timescale = 1e-4;
let time = 0;

const params = {
    mousex: mouse.x,
    mousey: mouse.y,
    ux: 0,
    uy: 0,
    panx,
    pany,
    scale,
};

pane.addBinding(params, "ux", {
    label: "real",
    readonly: true,
    format: (v) => v.toFixed(6),
});

pane.addBinding(params, "uy", {
    label: "imaginary",
    readonly: true,
    format: (v) => v.toFixed(6),
});

pane.addBinding(params, "scale", {
    label: "scale",
    readonly: true,
    format: (v) => v.toFixed(6),
});

pane.addBinding(params, "panx", {
    readonly: true,
    format: (v) => v.toFixed(6),
});

pane.addBinding(params, "pany", {
    readonly: true,
    format: (v) => v.toFixed(6),
});

// https://en.wikipedia.org/wiki/Sigmoid_function
function sigmoid(x: number) {
    return 1 / (1 + Math.exp(-x));
}

canvas.addEventListener("wheel", (e) => {
    // This maps the [-inf, inf] scroll value to [0, 2] so that we can shift the scale value and still use scroll magnitude
    const scaleFactor = sigmoid(e.deltaY / 100) * 2;

    // The pre and post cartesian deltas let us make the mouse position a "focal" point for zooming
    let x = (mouse.x - size / 2) * params.scale;
    let y = (mouse.y - size / 2) * params.scale;
    params.scale = Math.max(Math.min(params.scale * scaleFactor, 10), 0.00001);
    params.panx -= x - (mouse.x - size / 2) * params.scale;
    params.pany -= y - (mouse.y - size / 2) * params.scale;

    e.preventDefault(); // prevent pinch zoom effect
});

function iterate(t: DOMHighResTimeStamp) {
    const delta = t - lastFrame;

    const dx = mouse.x - mouse.lastx;
    const dy = mouse.y - mouse.lasty;

    params.ux =
        ((mouse.x - size / 2 - params.panx / params.scale) / size) *
        params.scale;
    params.uy =
        ((mouse.y - size / 2 + params.pany / params.scale) / size) *
        params.scale;

    if (mouse.down) {
        params.panx += dx * params.scale; // * delta;
        params.pany += dy * params.scale; // * delta;
    }

    // panx += panxa;
    // pany += panya;
    // panxa *= 0.999;
    // panya *= 0.999;

    if (!paused) {
        time += delta * timescale;
    }

    // mouseElement.innerText = `${Math.round(1000 / delta)}`;

    render(
        time,
        size,
        size,
        params.panx,
        params.pany,
        params.scale,
        generateColors("wow coolf")
    );

    lastFrame = t;
    mouse.lastx = mouse.x;
    mouse.lasty = mouse.y;

    // Do one iteration on CPU for path from mouse
    // const x = mouseX;
    // const y = mouseY;
    // const px = 0;
    // const py = 0;

    // let X = [px, py];
    // const c = [px, py];

    // overlayContext.clearRect(0, 0, overlay.width, overlay.height);

    // overlayContext.strokeStyle = `red`;

    // overlayContext.beginPath();

    // const r = 2;

    // for (let i = 0; i < Math.PI * 2; i += Math.PI / 100) {

    //     let X = [Math.cos(i), Math.sin(i)];
    //     let a = angle(X);

    //     const x1 = r * Math.cos(a);
    //     const y1 = r * Math.sin(a);

    //     const ux = (x1 * (width / scale)) / ratio + width / 2;
    //     const uy = (y1 * (height / scale)) + height / 2;

    //     overlayContext.lineTo(ux, uy);
    // }

    // overlayContext.stroke();
    // overlayContext.closePath();

    // overlayContext.beginPath();
    // overlayContext.strokeStyle = "red";

    // overlayContext.moveTo(x, y);

    // for (let i = 1; i < 10; i++) {
    //     // overlayContext.strokeStyle = `rgb(${(i) % 200}, ${(i) % 255}, ${(i) % 100})`;

    //     X = mandlebrot(X, c, T);

    //     // if (dist2(X) > 4) {
    //     // break;
    //     // }

    //     const a = (X[0] * (width / scale)) / ratio + width / 2;
    //     const b = (X[1] * (height / scale)) + height / 2;

    //     overlayContext.lineTo(a, b);
    //     overlayContext.stroke();
    // }

    // overlayContext.closePath();

    requestAnimationFrame(iterate);
}

requestAnimationFrame(iterate);
